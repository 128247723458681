import React from "react";

import Layout from "../components/layout";
import Header from "../components/header/header";
import Button from "../components/elements/button";
import Box from "../components/containers/box";

const NoPage = () => (
  <Layout>
    <section className='c-404'>
      <Header />
      <Box>
        <div className='row'>
          <div className='col-10 offset-1 col-lg-4 offset-lg-4 mt-8 text-center'>
            <img src='image/404.png' className='img-fluid mb-6' alt='Elettronica Scarpa - 404' />

            <h3 className='mb-4'>Oops! Pagina non trovata</h3>
            <Button customClass='e-button--inline-flex' to={"/"}>
              Torna alla home
            </Button>
          </div>
        </div>
      </Box>
    </section>
  </Layout>
);

export default NoPage;
